import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import store from './store'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    // This is for the scroll top when click on any router link
    routes: [
        {
            publicPath: '/',
            historyApiFallback: true,
            path: '/',
            component: () => import('./layouts/full-layout/Layout'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
                {
                    name: 'Dashboard',
                    path: '/',
                    component: () => import('./views/Dashboard'),
                },
                {
                    name: 'Exams',
                    path: 'exams',
                    component: () => import('./views/exams/List'),
                },
                {
                    name: 'Exams edit',
                    path: 'exams/:id',
                    component: () => import('./views/exams/Edit'),
                },
                /*{
                    name: 'Planner',
                    path: 'planning',
                    component: () => import('./views/planner/List'),
                },
                {
                    name: 'ExamStatuses',
                    path: 'examstatuses',
                    component: () => import('./views/examstatuses/List'),
                },*/
                {
                    name: 'Candidates',
                    path: 'candidates',
                    meta: { model: 'candidate', },
                    component: () => import('./views/candidates/List'),
                },
                {
                    name: 'Candidate edit',
                    path: 'candidates/:id',
                    meta: { model: 'candidate', },
                    component: () => import('./views/candidates/Edit'),
                },
                {
                    name: 'Certificates',
                    path: 'certificates',
                    component: () => import('./views/certificates/List'),
                },
                {
                    name: 'Dossiers',
                    path: 'dossiers',
                    component: () => import('./views/dossiers/List'),
                },
                {
                    name: 'Dossier edit',
                    path: 'dossiers/:id',
                    component: () => import('./views/dossiers/Edit'),
                },
                {
                    name: 'Assessors',
                    path: 'assessors',
                    meta: { model: 'assessor', },
                    component: () => import('./views/assessors/List'),
                },
                {
                    name: 'Assessors edit',
                    path: 'assessors/:id',
                    meta: { model: 'assessor', },
                    component: () => import('./views/assessors/Edit'),
                },
                {
                    name: 'Notes',
                    path: 'notes',
                    meta: { model: 'note', },
                    component: () => import('./views/notes/List'),
                },
                {
                    name: 'Profile',
                    path: 'profile',
                    component: () => import('./views/Profile'),
                },
                {
                    name: 'Translations',
                    path: 'translations',
                    component: () => import('./views/translations/List'),
                },
                {
                    name: 'Fields',
                    path: 'fields',
                    component: () => import('./views/fields/List'),
                },
                {
                    name: 'Forms',
                    path: 'Forms',
                    component: () => import('./views/forms/List'),
                },
                {
                    name: 'Forms edit',
                    path: 'Forms/:id',
                    component: () => import('./views/forms/Edit'),
                },
                {
                    name: 'Schemes',
                    path: 'schemes',
                    component: () => import('./views/schemes/List'),
                },
                {
                    name: 'Schemes edit',
                    path: 'schemes/:id',
                    component: () => import('./views/schemes/Edit'),
                },
                {
                    name: 'Tags',
                    path: 'tags',
                    component: () => import('./views/tags/List'),
                },
                {
                    name: 'Templates',
                    path: 'templates',
                    component: () => import('./views/templates/List'),
                },
                {
                    name: 'Templates edit',
                    path: 'templates/:id',
                    component: () => import('./views/templates/Edit'),
                },
                {
                    name: 'Locations',
                    path: 'locations',
                    component: () => import('./views/locations/List'),
                },
                {
                    name: 'Locations edit',
                    path: 'locations/:id',
                    component: () => import('./views/locations/Edit'),
                },
                {
                    name: 'Rooms',
                    path: 'Rooms',
                    component: () => import('./views/rooms/List'),
                },
                {
                    name: 'Rooms edit',
                    path: 'Rooms/:id',
                    component: () => import('./views/rooms/Edit'),
                },
                {
                    name: 'Companies',
                    path: 'companies',
                    component: () => import('./views/companies/List'),
                },
                {
                    name: 'Companies edit',
                    path: 'companies/:id',
                    meta: { model: 'company', },
                    component: () => import('./views/companies/Edit'),
                },
                {
                    name: 'Contact',
                    path: 'contacts',
                    meta: { model: 'contacts', },
                    component: () => import('./views/contacts/List'),
                },
                {
                    name: 'Contact edit',
                    path: 'contacts/:id',
                    meta: { model: 'contact', },
                    component: () => import('./views/contacts/Edit'),
                },
                {
                    name: 'Employees',
                    path: 'employees',
                    meta: { model: 'employees', },
                    component: () => import('./views/employees/List'),
                },
                {
                    name: 'Employees edit',
                    path: 'employees/:id',
                    meta: { model: 'employee', },
                    component: () => import('./views/employees/Edit'),
                },
                {
                    name: 'Settings Edit',
                    path: 'settings',
                    component: () => import('./views/settings/Edit'),
                },
                {
                    name: 'Role',
                    path: 'roles',
                    component: () => import('./views/roles/List'),
                },
                {
                    name: 'Role Edit',
                    path: 'roles/:id',
                    component: () => import('./views/roles/Edit'),
                },
                {
                    name: 'Workflow',
                    path: 'workflows',
                    component: () => import('./views/workflows/List'),
                },
                {
                    name: 'Workflow Edit',
                    path: 'workflows/:id',
                    component: () => import('./views/workflows/Edit'),
                },
            ]
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('./views/authentication/Login'),
        },
        {
            name: 'Error',
            path: '/error404',
            component: () => import('./views/Error'),
        },

        {
            path: '/authentication',
            component: () => import('./layouts/blank-layout/Blanklayout'),
        },
        {
            publicPath: '/certificate/check',
            path: '/certificate/check',
            component: () => import('./views/public/CertificateCheck'),
        },
        {
            publicPath: '/portal',
            historyApiFallback: true,
            path: '/portal',
            component: () => import('./views/public/portal/Portal'),
            meta: {
                requiresAuth: true
            },
        },
        {
            publicPath: '/register/candidate',
            path: '/register/candidate',
            component: () => import('./views/public/RegisterCandidate'),
            meta: {
                requiresAuth: false
            },
        },
        {
            name: 'Complete registration',
            path: '/completeregistration',
            publicPath: '/completeregistration',
            component: () => import('./views/authentication/CompleteRegistration'),
            meta: {
                requiresAuth: false
            },
        },
        {
            name: 'Password reset',
            path: '/resetpassword',
            publicPath: '/resetpassword',
            component: () => import('./views/authentication/PasswordReset'),
            meta: {
                requiresAuth: false
            },
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        } else {
            next('/login')
        }

    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.path != '/portal' && to.path != '/login' && (store.getters.getUser.is_candidate || store.getters.getUser.is_assessor || store.getters.getUser.is_contact)) {
        next('/portal')
        return
    }
    next()
})


export default router